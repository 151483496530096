@use './assets/scss/custom/components/animations';
@use './assets/scss/custom/components/buttons';
@use './assets/scss/custom/components/mixins';
@use './assets/scss/custom/components/typography';
@use './assets/scss/custom/fontawesome';

//import sections
@use './assets/scss/custom/sections/navbar';
@use './assets/scss/custom/sections/intro-section';
// @use './assets/scss/custom/sections/companies';
// @use './assets/scss/custom/sections/services';
// @use './assets/scss/custom/sections/testimonials';
// @use './assets/scss/custom/sections/faq';
// @use './assets/scss/custom/sections/portfolio';
// @use './assets/scss/custom/sections/get-started';
@use './assets/scss/custom/sections/footer';

@import './assets/scss/custom/custom';

.logo {
  height: 100px;
}

.filter-field {
  width: 20%;
  margin-bottom: 16px;
}
.table-container {
  margin: 16px;
}
