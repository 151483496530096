@use '../custom' as *;
@use '../components/mixins' as *;
.menu {
  @include gradient;

  li {
    padding: 0 0.7rem;

    a {
      color: $white;
      padding: 0 0.7rem;
      text-transform: capitalize;
      font-weight: 600;
    }
    &:hover {
      color: $white;
    }
  }
}
