@use '../custom' as *;

.display-2 {
  margin-bottom: 1rem;
  text-transform: capitalize;
  font-family: 'Poppins', sans-serif;

  &--intro {
    display: inline-block;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
  }

  &--description {
    font-size: 1rem;
    display: block;
    margin-top: 1.2rem;
    text-transform: none;

    @include media-breakpoint-up(lg) {
      font-size: 1.4rem;
      display: inline-block;
    }
  }
}

// HEADING LINE
.heading-line::before {
  content: '';
  width: 10rem;
  height: 0.1rem;
  display: block;
  margin: 0 auto;
  background-color: $primary;
}
.heading-line::after {
  content: '';
  width: 2rem;
  padding-top: 0.5rem;
  height: 0.2rem;
  display: block;
  margin: 0 auto;
  margin-bottom: 1rem;
  background-color: $primary;
}

// DISPLAY 3
.display-3 {
  font-family: 'Poppins', sans-serif;

  &--title {
    font-size: 1.2rem;
    text-transform: capitalize;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    display: inline-block;
    background-image: linear-gradient(to right, $primary, $secondary);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @include media-breakpoint-up(lg) {
      font-size: 1.8rem;
    }
  }
}

// BORDER RIGHT
.border-right {
  border-right: 0;

  @include media-breakpoint-up(sm) {
    border-right: 0.1rem solid $gray-300;
    height: 100%;
  }
}

// GRADIENT BG
.gradient {
  background: linear-gradient(to right, rgba($primary, 0.95), rgba($secondary, 0.95));
}
