//gradient mixin
@use '../custom' as *;
@mixin gradient {
  background: linear-gradient(to right, rgba(#000, 0.95), rgba($purple-dark, 0.95));
}
// ABSOLUTE CENTER
@mixin absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// TRANSITION PROPERTY
@mixin transitionProperty {
  transition-duration: 1s;
  transition-property: all;
}

@mixin gradientPurple {
  background: linear-gradient(to bottom, rgba($black, 0.95), rgba($purple-dark-2, 0.95));
}
