// MY THEME COLORS
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

$blue-dark: #031737;
$blue-light: #0596e4;
$purple-dark: #2c056e;
$purple-light: #bb6ef5;
$pink: #fe218b;
$footer: #151414;

$purple-dark-1: #10071f;
$purple-dark-2: #090a23;

$yellow: #fed700;
$black: #000;

// #######################################
//		NO NEED TO CUSTOM THE GRAY COLOR IF YOU DO NOT
// #######################################

$primary: $blue-dark; //$purple-dark;
$secondary: $blue-light; //$pink;

// ACCORDION OVERRIDES
$accordion-padding-y: 1.5rem; // LEAVE THE PADDING-Y TO DEFAULT
$accordion-padding-x: 1.5rem; // LEAVE THE PADDING-X TO DEFAULT
$accordion-icon-color: $primary;
$accordion-icon-active-color: $secondary;
// $accordion-icon-width:                    1.875rem; // LEAVE THE WIDTH TO DEFAULT
$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/> <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/>  <path d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/></svg>");

// ##########################################
//   OPTION 1:
// - IMPORT BOOTSTRAP ( The entire bootstrap with everything)
// ##########################################
@import 'bootstrap/scss/bootstrap.scss';

// ##########################################
//   OPTION 2:
// - IMPORT IMPORT ONLY THE PART OF BOOTSTRAP YOU WANT TO USE
// ##########################################

// @import "../node_modules/bootstrap/scss/functions";
// @import "../node_modules/bootstrap/scss/variables";
// @import "../node_modules/bootstrap/scss/mixins";

// Include any optional Bootstrap components as you like
// @import "../node_modules/bootstrap/scss/root";
// @import "../node_modules/bootstrap/scss/reboot";
.home-bg {
  background-image: linear-gradient(rgba(44, 11, 208, 0.5), rgba(41, 116, 208, 0.7)),
    url('../../images/pexels-photo-1552242.png');
  height: 80%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  @media (max-width: 991px) {
    display: none;
  }
}
